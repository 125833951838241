import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: <HomeIcon />,
    roles: ['admin', 'user', 'create/maintain'],
  },
  {
    title: 'companies',
    path: '/companies',
    icon: <BusinessIcon />,
    roles: ['admin', 'user', 'create/maintain'],
  },
  {
    title: 'departments',
    path: '/departments',
    icon: <ApartmentIcon />,
    roles: ['admin', 'user', 'create/maintain'],
  },
  {
    title: 'locations',
    path: '/locations',
    icon: <LocationOnIcon />,
    roles: ['admin', 'user', 'create/maintain'],
  },
  {
    title: 'categories',
    path: '/categories',
    icon: <CategoryIcon />,
    roles: ['admin', 'user', 'create/maintain'],
  },
  {
    title: 'people',
    path: '/people',
    icon: <GroupIcon />,
    roles: ['admin', 'user', 'create/maintain'],
  },
  {
    isDivider: true,
    roles: ['admin', 'user', 'create/maintain'],
  },
  {
    title: 'assets',
    path: '/asset-list',
    icon: <InventoryIcon />,
    roles: ['admin', 'user', 'create/maintain'],
  },
  {
    title: 'reporting',
    path: '/reporting',
    icon: <ChatIcon />,
    roles: ['admin'],
  },
  {
    title: 'user',
    path: '/user',
    icon: <PersonIcon />,
    roles: ['admin'],
  },
];

export default navConfig;
